<template>
  <div>
    <div class="popup d-none d-md-block">
      <div class="container-lg" >
        <div class="row h-100 m-0 justify-content-center">
          <div class="col-12 p-3 p-md-5">
            <div class="popup_inner">
              <div class="overlay"></div>
              <div class="row mx-0 above">
                <div class="col-12 p-0">
                  <div class="row mx-0 justify-content-between">
                    <div class="col-auto p-0">
                      <ios-arrow-round-back-icon class="inline-icon big cursor close" @click="closeItem" v-if="Object.keys(activeAsset).length !== 0" />
                    </div>
                    <div class="col-auto p-0">
                      <ios-close-icon class="inline-icon big cursor close" @click="$emit('close')"/>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="col-12 bold heading mb-3">
                      Assets | {{defaultCurrency}}{{parseFloat(Math.round((zakaatAssetsTotal) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                    </div>
                  </div>
                  <div class="row m-0 justify-content-center d-md-none" v-if="Object.keys(activeAsset).length === 0">
                    <div class="col-sm-6 col-md-3 mb-3" v-for="asset in assetsInfo" :key="asset.link">
                      <AssetItem
                        :title="asset[asset.item].title"
                        :info="asset[asset.item].info"
                        :total="asset[asset.item].total"
                        :link="asset[asset.item].link"
                        :type="asset.item"
                        @addItem="showAssetType(asset[asset.item])"
                        :active="activeAsset"
                      />
                    </div>
                  </div>
                  <div class="row m-0 justify-content-center d-none d-md-flex">
                    <div class="col-sm-6 col-md-3 mb-3" v-for="asset in assetsInfo" :key="asset.link">
                      <AssetItem
                        :title="asset[asset.item].title"
                        :info="asset[asset.item].info"
                        :total="asset[asset.item].total"
                        :link="asset[asset.item].link"
                        :type="asset.item"
                        @addItem="showAssetType(asset[asset.item])"
                        :active="activeAsset"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <AddCommodities v-if="activeAsset.link === 'isGold'" />
                      <AddMoney v-if="activeAsset.link === 'isMoney'" />
                      <AddInvestments v-if="activeAsset.link === 'isInvestments'" />
                      <AddPolicies v-if="activeAsset.link === 'isPolicies'" />
                      <AddBusiness v-if="activeAsset.link === 'isBusiness'" />
                      <AddDebtors v-if="activeAsset.link === 'isDebtors'" />
                      <AddPartnership v-if="activeAsset.link === 'isPartnership'" />
                    </div>
                  </div>
                  <div class="row" v-if="Object.keys(activeAsset).length === 0 ">
                    <div class="col-12">
                      <div class="row" v-if="currentZakaah.zakaatAssets.businessAssets.length > 0">
                        <div class="col-12 bold smallheading my-4">
                          Businesses
                        </div>
                      </div>
                      <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.businessAssets" :key="key">
                        <div class="col-8">
                          <div class="row">
                            <div class="col-12 bold mb-1" v-if="asset.description">
                              {{asset.description}}
                            </div>
                            <div class="col-12 font12" v-if="asset.businessAssetType">
                              {{ displayDescription(asset.businessAssetType) }}
                            </div>
                          </div>
                        </div>
                        <div class="col text-end bold">
                          {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                        </div>
                      </div>
                      <div class="row" v-if="currentZakaah.zakaatAssets.commodities.length > 0">
                        <div class="col-12 bold smallheading my-4">
                          Commodities
                        </div>
                      </div>
                      <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.commodities" :key="key">
                        <div class="col-8">
                          <div class="row">
                            <div class="col-12 bold mb-1" v-if="asset.description">
                              {{asset.description}}
                            </div>
                            <div class="col-12 font12" v-if="asset.commodityType">
                              {{displayDescription(asset.commodityType)}}<span class="ms-1" v-if="asset.commodityType === 'GOLD_JEWELLERY' || asset.commodityType === 'GOLD_KRUGERRAND_COIN'">{{ displayDescription(asset.proportion) }}</span>
                            </div>
                            <div class="col-12 font12">
                              {{ (asset.commodityType === 'GOLD_KRUGERRAND_COIN' ? `${asset.quantity} units` : `${asset.weightInGrams}g` ) }}
                            </div>
                          </div>
                        </div>
                        <div class="col text-end bold">
                          {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                        </div>
                      </div>
                      <div class="row mx-0" v-if="currentZakaah.zakaatAssets.debtorAssets.length > 0">
                        <div class="col-12 bold smallheading my-4">
                          Debtors
                        </div>
                      </div>
                      <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.debtorAssets" :key="key">
                        <div class="col-8">
                          <div class="row">
                            <div class="col-12 bold mb-1" v-if="asset.description">
                              {{asset.description}}
                            </div>
                            <div class="col-12 font12" v-if="asset.debtorsAssetType">
                              {{ displayDescription(asset.debtorsAssetType) }}
                            </div>
                          </div>
                        </div>
                        <div class="col text-end bold">
                          {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                        </div>
                      </div>
                      <div class="row" v-if="currentZakaah.zakaatAssets.investmentAssets.length > 0">
                        <div class="col-12 bold smallheading my-4">
                          Investments
                        </div>
                      </div>
                      <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.investmentAssets" :key="key">
                        <div class="col-8">
                          <div class="row">
                            <div class="col-12 bold mb-1" v-if="asset.description">
                              {{asset.description}}
                            </div>
                            <div class="col-12 font12" v-if="asset.investmentAssetType">
                              {{ displayDescription(asset.investmentAssetType) }}
                            </div>
                            <div class="col-12 font12">
                              {{asset.currency.symbol}} {{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                            </div>
                          </div>
                        </div>
                        <div class="col text-end bold">
                          {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                        </div>
                      </div>
                      <div class="row" v-if="currentZakaah.zakaatAssets.cashAssets.length > 0">
                        <div class="col-12 bold smallheading my-4">
                          Money
                        </div>
                      </div>
                      <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.cashAssets" :key="key">
                        <div class="col-8">
                          <div class="row">
                            <div class="col-12 bold mb-1" v-if="asset.description">
                              {{asset.description}}
                            </div>
                            <div class="col-12 font12" v-if="asset.cashAssetType">
                              {{ displayDescription(asset.cashAssetType) }}
                            </div>
                            <div class="col-12 font12">
                              {{asset.currency.symbol}} {{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                            </div>
                          </div>
                        </div>
                        <div class="col text-end bold">
                          {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                        </div>
                      </div>
                      <div class="row" v-if="currentZakaah.zakaatAssets.partnershipAssets.length > 0">
                        <div class="col-12 bold smallheading my-4">
                          Partenrships
                        </div>
                      </div>
                      <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.partnershipAssets" :key="key">
                        <div class="col-8">
                          <div class="row">
                            <div class="col-12 bold mb-1" v-if="asset.companyName">
                              {{asset.companyName}}
                            </div>
                            <div class="col-12 font12">
                              {{defaultCurrency}}{{parseFloat(Math.round((asset.netWorth) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }} | {{asset.percentageOwned}}%
                            </div>
                          </div>
                        </div>
                        <div class="col text-end bold">
                          {{defaultCurrency}}{{parseFloat(Math.round((asset.total) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                        </div>
                      </div>
                      <div class="row" v-if="currentZakaah.zakaatAssets.policyAssets.length > 0">
                        <div class="col-12 bold smallheading my-4">
                          Policies
                        </div>
                      </div>
                      <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.policyAssets" :key="key">
                        <div class="col-8">
                          <div class="row">
                            <div class="col-12 bold mb-1" v-if="asset.description">
                              {{asset.description}}
                            </div>
                            <div class="col-12 font12" v-if="asset.policiesAssetType">
                              {{ displayDescription(asset.policiesAssetType) }}
                            </div>
                          </div>
                        </div>
                        <div class="col text-end bold">
                          {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                        </div>
                      </div>
                      <div class="row mt-5" v-if="zakaatAssetsTotal > 0">
                        <div class="col-12">
                          <Button color="green" btnText="Save Assets" icon="arrow" @buttonClicked="save" class="mb-1">
                            <IconArrowForward color="gold" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup d-md-none ">
      <div class="row h-100 m-0 justify-content-center">
        <div class="col-12 p-0">
          <div class="popup_inner">
            <div class="row mx-0 env-padding">
              <div class="col-12">
                <div class="row mx-0 justify-content-between">
                  <div class="col-auto p-0">
                    <ios-arrow-round-back-icon class="inline-icon big cursor close" @click="closeItem" v-if="Object.keys(activeAsset).length !== 0" />
                  </div>
                  <div class="col-auto p-0">
                    <ios-close-icon class="inline-icon big cursor close" @click="$emit('close')"/>
                  </div>
                </div>
                <div class="row m-0">
                  <div class="col-12 bold heading mb-3">
                    Assets | {{defaultCurrency}}{{parseFloat(Math.round((zakaatAssetsTotal) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                  </div>
                </div>
                <div class="row m-0 justify-content-center d-md-none" v-if="Object.keys(activeAsset).length === 0">
                  <div class="col-sm-6 col-md-3 mb-3" v-for="asset in assetsInfo" :key="asset.link">
                    <AssetItem
                      :title="asset[asset.item].title"
                      :info="asset[asset.item].info"
                      :total="asset[asset.item].total"
                      :link="asset[asset.item].link"
                      :type="asset.item"
                      @addItem="showAssetType(asset[asset.item])"
                      :active="activeAsset"
                    />
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-12 p-0">
                    <AddCommodities v-if="activeAsset.link === 'isGold'" />
                    <AddMoney v-if="activeAsset.link === 'isMoney'" />
                    <AddInvestments v-if="activeAsset.link === 'isInvestments'" />
                    <AddPolicies v-if="activeAsset.link === 'isPolicies'" />
                    <AddBusiness v-if="activeAsset.link === 'isBusiness'" />
                    <AddDebtors v-if="activeAsset.link === 'isDebtors'" />
                    <AddPartnership v-if="activeAsset.link === 'isPartnership'" />
                  </div>
                </div>
                <div class="row" v-if="Object.keys(activeAsset).length === 0 ">
                  <div class="col-12">
                    <div class="row" v-if="currentZakaah.zakaatAssets.businessAssets.length > 0">
                      <div class="col-12 bold smallheading my-4">
                        Businesses
                      </div>
                    </div>
                    <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.businessAssets" :key="key">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12 bold mb-1" v-if="asset.description">
                            {{asset.description}}
                          </div>
                          <div class="col-12 font12" v-if="asset.businessAssetType">
                            {{ displayDescription(asset.businessAssetType) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-end bold text-nowrap">
                        {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                      </div>
                    </div>
                    <div class="row" v-if="currentZakaah.zakaatAssets.commodities.length > 0">
                      <div class="col-12 bold smallheading my-4">
                        Commodities
                      </div>
                    </div>
                    <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.commodities" :key="key">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12 bold mb-1" v-if="asset.description">
                            {{asset.description}}
                          </div>
                          <div class="col-12 font12" v-if="asset.commodityType">
                            {{displayDescription(asset.commodityType)}}<span class="ms-1" v-if="asset.commodityType === 'GOLD_JEWELLERY' || asset.commodityType === 'GOLD_KRUGERRAND_COIN'">{{ displayDescription(asset.proportion) }}</span>
                          </div>
                          <div class="col-12 font12">
                            {{ (asset.commodityType === 'GOLD_KRUGERRAND_COIN' ? `${asset.quantity} units` : `${asset.weightInGrams}g` ) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-end bold text-nowrap">
                        {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                      </div>
                    </div>
                    <div class="row" v-if="currentZakaah.zakaatAssets.debtorAssets.length > 0">
                      <div class="col-12 bold smallheading my-4">
                        Debtors
                      </div>
                    </div>
                    <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.debtorAssets" :key="key">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12 bold mb-1" v-if="asset.description">
                            {{asset.description}}
                          </div>
                          <div class="col-12 font12" v-if="asset.debtorsAssetType">
                            {{ displayDescription(asset.debtorsAssetType) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-end bold text-nowrap">
                        {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                      </div>
                    </div>
                    <div class="row" v-if="currentZakaah.zakaatAssets.investmentAssets.length > 0">
                      <div class="col-12 bold smallheading my-4">
                        Investments
                      </div>
                    </div>
                    <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.investmentAssets" :key="key">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12 bold mb-1" v-if="asset.description">
                            {{asset.description}}
                          </div>
                          <div class="col-12 font12" v-if="asset.investmentAssetType">
                            {{ displayDescription(asset.investmentAssetType) }}
                          </div>
                          <div class="col-12 font12">
                            {{asset.currency.symbol}} {{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-end bold text-nowrap">
                        {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                      </div>
                    </div>
                    <div class="row" v-if="currentZakaah.zakaatAssets.cashAssets.length > 0">
                      <div class="col-12 bold smallheading my-4">
                        Money
                      </div>
                    </div>
                    <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.cashAssets" :key="key">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12 bold mb-1" v-if="asset.description">
                            {{asset.description}}
                          </div>
                          <div class="col-12 font12" v-if="asset.cashAssetType">
                            {{ displayDescription(asset.cashAssetType) }}
                          </div>
                          <div class="col-12 font12">
                            {{asset.currency.symbol}} {{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-end bold text-nowrap">
                        {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                      </div>
                    </div>
                    <div class="row" v-if="currentZakaah.zakaatAssets.partnershipAssets.length > 0">
                      <div class="col-12 bold smallheading my-4">
                        Partenrships
                      </div>
                    </div>
                    <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.partnershipAssets" :key="key">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12 bold mb-1" v-if="asset.companyName">
                            {{asset.companyName}}
                          </div>
                          <div class="col-12 font12">
                            {{defaultCurrency}}{{parseFloat(Math.round((asset.netWorth) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }} | {{asset.percentageOwned}}%
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-end bold text-nowrap">
                        {{defaultCurrency}}{{parseFloat(Math.round((asset.total) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                      </div>
                    </div>
                    <div class="row" v-if="currentZakaah.zakaatAssets.policyAssets.length > 0">
                      <div class="col-12 bold smallheading my-4">
                        Policies
                      </div>
                    </div>
                    <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.policyAssets" :key="key">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12 bold mb-1" v-if="asset.description">
                            {{asset.description}}
                          </div>
                          <div class="col-12 font12" v-if="asset.policiesAssetType">
                            {{ displayDescription(asset.policiesAssetType) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-end bold text-nowrap">
                        {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                      </div>
                    </div>
                    <div class="row my-5" v-if="zakaatAssetsTotal > 0">
                      <div class="col-12 mb-5">
                        <Button color="green" btnText="Save Assets" icon="arrow" @buttonClicked="save" class="mb-1">
                          <IconArrowForward color="gold" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    AddCommodities: defineAsyncComponent(() => import('./views/AddCommodities.vue')),
    AddInvestments: defineAsyncComponent(() => import('./views/AddInvestments.vue')),
    AddMoney: defineAsyncComponent(() => import('./views/AddMoney.vue')),
    AddPolicies: defineAsyncComponent(() => import('./views/AddPolicies.vue')),
    AddBusiness: defineAsyncComponent(() => import('./views/AddBusiness.vue')),
    AddDebtors: defineAsyncComponent(() => import('./views/AddDebtors.vue')),
    AssetItem: defineAsyncComponent(() => import('./components/AssetItem.vue')),
    AddPartnership: defineAsyncComponent(() => import('./views/AddPartnership.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue')),
    'ios-arrow-round-back-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-round-back.vue'))
  },
  name: 'Assets',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      activeAsset: {},
      assetsInfo: [
        {
          item: 'commodities',
          commodities: {
            title: 'Gold & Silver',
            info: 'Zakaat on Jewellery and precious metal coins is based on the weight of gold/silver - Simply enter the appropriate weights.<br/><br/>Zakaat on KrugerRands (fixed weight) is based on the number of coins in possession.',
            link: 'isGold',
            total: 0
          }
        },
        {
          item: 'cashAssets',
          cashAssets: {
            title: 'Money',
            info: 'Zakaat should be paid on 2.5% of all cash and bank balances standing in your favour. Where practical reduce the amount by interest accrued.',
            link: 'isMoney',
            total: 0
          }
        },
        {
          item: 'investmentAssets',
          investmentAssets: {
            title: 'Investments',
            info: 'Zakaat is payable on the current market price of all investmentAssets and shares.Where practical reduce the amount by interest accrued.<br/><br/>Some of the items listed under "Select Investment Type" are impermissable as they are essentially interest bearing instuments.<br/><br/>The payment of zakaat on the items mentioned do not legitimise or clense them.<br/><br/>It is better to avoid trading in or holding (impermissable) assets.',
            link: 'isInvestments',
            total: 0
          }
        },
        {
          item: 'businessAssets',
          businessAssets: {
            title: 'Business',
            info: "Include all Trade Debtors: Do not deduct bad debts in this section.<br/><br/>Use the current market price to determine the appropiate value for your inventory.<br/><br/>It might be more practical to work out a value based on the wholesale price assuming all the stock were to be sold in one single transaction on a willing buyer/seller basis.<br/><br/>Professional or service orientated businesses e.g. lawyers, doctors, plumbers etc. should not capture monies owing to them for services rendered. The 'Debtors Section' should be used for this.",
            link: 'isBusiness',
            total: 0
          }
        },
        {
          item: 'policyAssets',
          policyAssets: {
            title: 'Policies',
            info: 'For conventional (non-Sharia compliant policies) first establish the premiums paid to date and the current value of the policy (whether accessible or not) and pay on the lesser amount as only that portion that equals your contributions (i.e. premiums) is halal. In other words, reduce current value by the amount of interest accrued.<br/><br/>For Sharia compliant policies pay Zakaat on the current value of the policy (whether accessible or not).<br/><br/>Zakaat is payable on all monies owing to you if you have a reasonable expectation that such monies are secure (will be paid).',
            link: 'isPolicies',
            total: 0
          }
        },
        {
          item: 'debtorAssets',
          debtorAssets: {
            title: 'Debtors',
            info: 'akaat is payable on all monies owing to you if you have a reasonable expectation that such monies are secure.',
            link: 'isDebtors',
            total: 0
          }
        },
        {
          item: 'partnershipAssets',
          partnershipAssets: {
            title: 'Partnership',
            info: 'This section is to be filled in by people in a partnership type arrangement. Do not use this section for shares on a stock exchange. If there is no balance sheet or if its not current then the net-worth of the company could be equated to the value all share holders are prepared to sell the company for in its entirety, i.e. including stock, fixtures, fittings, motor vehicles etc.',
            link: 'isPartnership',
            total: 0
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'currentZakaah',
      'zakaatAssetsTotal'
    ])
  },
  methods: {
    closeItem () {
      this.activeAsset = {}
    },
    showAssetType (val) {
      Object.assign(this.activeAsset, val)
    },
    save () {
      this.$emit('close')
      window.scrollTo(0, 0)
    },
    displayDescription (val) {
      if (typeof val === 'string' || val instanceof String) {
        const splitItem = val.split('_')
        const arr = []
        splitItem.forEach(item => {
          const lowercase = item.toLowerCase()
          const caps = lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
          arr.push(caps)
        })
        const str = arr.slice(0, arr.length).join(' ')
        return str
      }
    }
  }
}
</script>
<style scoped>
  .popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.8);
    top: 0;
    left: 0;
    z-index: 99;
  }
  .popup_inner  {
    margin: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    background-color: #FFFFFF;
    position: relative;
    padding: 0;
  }
  .heading {
    color: var( --green-color-dark );
    font-family: "quicksand_medium", Sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5em;
  }
  .smallheading {
    color: var( --green-color );
    font-family: "quicksand_medium", Sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .asset_item {
    border: 2px solid var(--green-color-dark);
    border-radius: 15px;
  }
  .close {
    color: var( --green-color );
    font-size: 3rem;
  }
  @media (min-width: 992px) {
    .popup_inner  {
      animation-duration: 1.2s;
      border-radius: 22px 22px 22px 22px;
      box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
      margin: 0;
      height: calc(100vh - 6rem);
      width: 100%;
      overflow: hidden;
      background-color: #FFFFFF;
      background-image: url(../../../../../assets/images/bg.png);
      position: relative;
      padding: 1rem;
    }
    .overlay {
      background-color: transparent;
      background-image: linear-gradient(180deg, #FFFFFFF7 0%, #FFFFFF 100%);
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .above {
      position: relative;
      z-index: 2;
      color: var(--normal-color-dark);
      height: calc(100vh - 6rem - 2rem);
      overflow-y: auto;
      overflow-x: hidden;
    }
    .heading {
      font-size: 44px;
    }
  }

</style>
